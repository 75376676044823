:root{
  --main-color: #00d7d7;
}

.text-white{
  color: #fff;
}

a.text-white:hover{
  color: var(--main-color)!important;
}

ul.no-bullets{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

img.sold{
  filter: grayscale(100%);
}

img.thumbnail{
  max-width: 100%;
  max-height: 20em;
  object-fit: cover;
}

span.details-desc{
  text-transform: uppercase;
  color: gray;
  font-size: calc(3px + 0.5vw);
  margin-right: 0.4vw; 
}

.bg-grey{
  background-color: #f4f4f4;
}

.text-align-center{
  text-align: center;
}

.login-form-container{
  width: 30vw;
  background-color: white;
  border-radius: 3%;
  padding: 2em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/*
* CSS for public page
* Responsive rules (@media) on the bottom
*/

.navbar-dark{
  background-color: black;
  color: white;
  height: 15vh;
}

.navbar-logo{
  max-height: 10vh;
}

.navbar-logo-container{
  margin-left: calc(10vw + 5%);
  margin-right: calc(10vw + 5%);
}

.navbar-link{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  font-size: calc(100% + .3vw);
  transition: .3s; 
}

.menu-item-container{
  padding: .5rem 1rem;
}

.menu-item-container:hover > .top-animation{
  width:100%;
  margin-left: 0;
  margin-right: 0;
  background-color: var(--main-color);
  transform: scale(1);
}

.menu-item-container-active > .top-animation{
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  background-color: var(--main-color);
}

.menu-item-container-active > .navbar-link{
  color: var(--main-color);
}

.menu-item-container span{
  display: block;
}

.navbar-link:hover{
  color:var(--main-color);
}

.top-animation{
  box-sizing: border-box;
  background-color: #000;
  position: relative;
  width: 0;
  margin-left: 50%;
  margin-right: 50%;
  float: left;
  height: 3px;
  transition: 0.3s;
  transform-origin: left;
  transition-timing-function: cubic-bezier(0.42,0.01,0.58,1);
}

.public-container{
  background-color: black;
  min-height: 80vh;
}

.heading-container{
  min-height: 40vh;
  padding-top: 15vh;
  padding-bottom: 15vh;
}

.heading-row{
  width: 100%;
}

h1.heading{
  font-family: 'Roboto', sans-serif;
  font-size: calc(50px + 0.5vw);
  font-weight: 500;
  color: white;
}

h2.heading{
  font-family: 'Roboto', sans-serif;
  font-size: calc(20px + 0.5vw);
  font-weight: 500;
  color: white;
}

p.sub-text{
  font-family: 'Roboto', sans-serif;
  color: rgb(170,169,169);
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.card-black{
  background-color: #000;
  color: white;
  border: 1px #0f0f0f solid;
  border-radius: 5px;
}

.card-img{
  max-width: 20vw;
  max-height: 36vh;
  margin: calc(var(--bs-gutter-x) * .5);
  object-fit: cover;
}

.detail-row{
  /*border-bottom: #fff 1px solid;*/
}

.detail-desc{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  margin-right: 1vw;
  font-size: calc(10px + .2vw);
}

.detail-val{
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  /*font-size: 1vw;*/
  font-size: calc(100%)
}

.detail-button{
  background-color: black;
  color: white;
  border-color: var(--main-color);
}
.detail-button:hover{
  background-color: var(--main-color);
  color: black;
  border-color: var(--main-color);
}

.detail-button:active{
  background-color: var(--main-color);
  color: black;
  border-color: var(--main-color);
}

.detail-button:focus{
  background-color: var(--main-color);
  color: black;
  border-color: var(--main-color);
  box-shadow: 0 0 0 .25rem var(--main-color);
}

@media only screen and (max-width: 992px) {
  .navbar-logo-container{
    margin-left: 2vw;
    margin-right: 2vw;
  }
}

a.text-link{
  color: var(--main-color);
}

.footer{
  color: white;
  font-size: 12px;
}